<template>
  <div
    class="searchResult"
    v-watermark
  >
    <commonNavBar :title="'消防主机型号查询'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay
      :show="loadingShow"
      class-name="myLoading"
    >
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div class="searchBox">
      <form action="/">
        <van-search
          :value="$route.query.text"
          show-action
          left-icon=""
          :clearable="false"
          readonly
          @click="$router.back()"
        >
          <template #action>
            <div @click="$router.back()">取消</div>
          </template>
          <template #label>
            <van-dropdown-menu active-color="#1989fa">
              <van-dropdown-item
                :value="+$route.query.type"
                :options="option1"
                disabled
              />
            </van-dropdown-menu>
          </template>
        </van-search>
      </form>
      <div
        class="searchBar"
        v-if="companyList.length"
      >
        <span class="label">搜索结果:共{{ total }}条</span>
      </div>
      <!-- 结果列表 -->
      <div
        class="listBox"
        v-if="companyList.length"
        @scroll="onScroll"
        ref="listBox"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :error.sync="error"
          error-text="请求失败,点击重新加载!"
        >
          <!-- 卡片 -->
          <div
            class="myCard"
            v-for="(item, index) in companyList"
            :key="index"
            @click="
              $router.push(`/workTable/hostModelAddressBookInfo/${item.id}`)
              "
          >
            <div class="name">
              <span class="label">{{ item.model || "--" }}</span>
              <van-tag
                type="danger"
                v-if="item.status === 1"
              >停用</van-tag>
              <van-tag
                type="success"
                v-if="item.status === 0"
              >在用</van-tag>
            </div>
            <p>主机品牌:{{ item.brand || "--" }}</p>
            <p>主机类型:{{ item.modelType || "--" }}</p>
          </div>
        </van-list>
      </div>

      <!-- 空结果 -->
      <div
        class="empty"
        v-else
      >
        <van-empty
          image="search"
          description="没有符合条件的数据"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getHostModelList } from '@/api/hostModel'

export default {
  name: 'searchResult',
  data() {
    return {

      option1: [
        { text: '型号', value: 0 },
        { text: '品牌', value: 1 }
      ],
      companyList: [],
      loading: false,
      finished: false,
      top: 0,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      error: false,
      loadingShow: false

    }
  },
  // 激活页面
  activated() {
    if (this.$refs.listBox) {
      this.$refs.listBox.scrollTop = this.top
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/workTable/hostModelAddressBookInfo')) {
      next((vm) => {
        if (!vm.companyList.length) {
          vm.onLoad()
        }
      })
    } else {
      next((vm) => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        vm.onLoad()
      })
    }
  },

  methods: {
    // 加载列表
    async onLoad() {
      if (this.pageNum === 1) {
        this.loadingShow = true
      }
      try {
        const { data } = await getHostModelList({
          type: +this.$route.query.type,
          'page.current': this.pageNum,
          'page.size': this.pageSize,
          'sort.key': 'etm.update_datetime',
          'sort.value': 'desc',
          search: this.$route.query.text,
          fuzzy: 1
        })
        this.total = data.data.total
        this.pageNum = this.pageNum + 1
        // 追加数据
        const results = data.data.records
        this.companyList.push(...results)
        this.loading = false
        if (!results.length) {
          this.finished = true
        }
        if (this.loadingShow) {
          this.loadingShow = false
        }
      } catch (error) {
        if (error.message === '取消请求') {
          this.error = false
          return
        }
        // 加载状态结束
        this.loading = false
        this.error = true
        if (this.loadingShow) {
          this.loadingShow = false
        }
        if (error?.response?.status === 401) {
          return
        }
        if (error.message.includes('timeout')) {
          if (this.$route.path === '/workTable/hostModelResult') {
            this.$toast.fail({
              message: '网络不好，请稍后再试!',
              duration: 500
            })
          }
        }
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
.searchResult {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;

  ::v-deep {
    .myLoading {
      top: 100px;
      bottom: 0;
      height: auto;

      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-search__content {
        padding-left: 0;
      }

      .van-dropdown-menu {
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;

        .van-dropdown-menu__bar {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          box-shadow: none;

          .van-ellipsis {
            padding-right: 5px;
            font-size: 14px;
          }
        }

        .van-dropdown-menu__title::after {
          right: 1px;
        }
      }

      .van-dropdown-item {
        .van-dropdown-item__content {
          .van-dropdown-item__option {
            .van-cell__title {
              padding-left: 25px;
              font-size: 14px;
            }
          }
        }
      }

      .van-dropdown-menu__item--disabled {
        .van-dropdown-menu__title {
          color: #323233;
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;
        padding-bottom: 5px;
        background-color: #eeeeee;

        .label {
          font-size: 12px;
          color: #aaaaaa;
          padding-top: 5px;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .empty {
      height: calc(100% - 54px - 24px);
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .listBox {
      height: calc(100% - 24px - 54px);
      overflow: auto;
      padding: 0 7px;

      // padding-top: 10px;
      // padding: 9px 0;
      // padding-bottom: 0px;
      .myCard {
        // margin-bottom: 9px;
        // background-color: white;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 5px;

        .name {
          font-size: 0;
          margin-bottom: 5px;

          .label {
            font-size: 14px;
            margin-right: 10px;
            font-weight: 700;
          }
        }

        >p {
          font-size: 12px;
          color: #969799;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
